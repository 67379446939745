<div class="container ">

  <form [formGroup]="detailForm" (ngSubmit)="onSave()">
    <div class="row">
      <div class="col-9 flex" style="display: flex;justify-content: center; ">
        <img id="tool_detail_photo" class="img-defaultphoto-icon" src="" style="width:150px; height: 150px;">
      </div>
    </div>


    <div class="row">

      <div *showIfDebugMode class="col-3 flex">
        <mat-form-field class="w-100">
          <mat-label>{{ 'ID' | translate }}</mat-label>
          <input id="id" matInput formControlName="id">
        </mat-form-field>
      </div>

      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>{{ 'NAME' | translate }}</mat-label>
          <mat-select formControlName="category">
            @for (category of categories; track category) {
              <mat-option [value]="category">{{ category.name }}</mat-option>
            }
          </mat-select>
        </mat-form-field>

        @if (!hasCategories()) {
          <mat-error>{{ 'CATEGORIES.MISSING' | translate }}</mat-error>
        }
      </div>

      <div class="col-3 flex">
        <mat-form-field class="w-100">
          <mat-label>{{ 'RFID' | translate }}</mat-label>
          <input id="rfid" matInput formControlName="rfid">
          <mat-error>{{ FormErrorHelper.getErrorMessage(detailForm.controls.rfid) }}</mat-error>
        </mat-form-field>
      </div>

    </div>

    <div class="row">

      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>{{ 'DATE.ACQUISITION' | translate }}</mat-label>
          <input matInput [matDatepicker]="acquisitionDatePicker" formControlName="acquisitionDate">
          <mat-hint>{{ 'DATE.FORMAT' | translate }}</mat-hint>
          <mat-datepicker-toggle matIconSuffix [for]="acquisitionDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #acquisitionDatePicker></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>{{ 'DATE.LAST_TRANSACTION_DATE' | translate }}</mat-label>
          <input id="lastTransactionDate" matInput formControlName="lastTransactionDate">
        </mat-form-field>
      </div>

      <div class="col-3">
        <mat-form-field  class="w-100">
          <mat-label>{{ 'MANUFACTURER.MANUFACTURER' | translate }}</mat-label>
          <mat-select formControlName="manufacturer">
            @for (manufacturer of manufacturers; track manufacturer) {
              <mat-option [value]="manufacturer">{{ manufacturer.name }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>

    </div>

    <div class="row">

      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>{{ 'TOOLTYPE' | translate }}</mat-label>
          <input id="toolType" matInput formControlName="toolType">
        </mat-form-field>
      </div>

      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>{{ 'WARRANTY.CHOOSE_WARRANTY_UNTIL' | translate }}</mat-label>
          <input matInput [matDatepicker]="warrantyUntilDatePicker" formControlName="warrantyUntil">
          <mat-hint>{{ 'DATE.FORMAT' | translate }}</mat-hint>
          <mat-datepicker-toggle matIconSuffix [for]="warrantyUntilDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #warrantyUntilDatePicker></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>{{ 'SERIALNUMBER' | translate }}</mat-label>
          <input id="serialNumber" matInput formControlName="serialNumber">
        </mat-form-field>
      </div>

    </div>

    <div class="row">

      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>{{ 'ACQUISITION_PRICE' | translate }}</mat-label>
          <input id="acquisitionPrice" matInput formControlName="acquisitionPrice">
        </mat-form-field>
      </div>

      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>{{ 'INVENTORY_NUMBER' | translate }}</mat-label>
          <input id="inventoryNumber" matInput formControlName="inventoryNumber">
        </mat-form-field>
      </div>

      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>{{ 'UNIT_NUMBER' | translate }}</mat-label>
          <input id="unitNumber" matInput formControlName="unitNumber">
        </mat-form-field>
      </div>

    </div>

    <div class="row">

      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>{{ 'LENGTH' | translate }}</mat-label>
          <input id="length" matInput formControlName="length">
        </mat-form-field>
      </div>

      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>{{ 'WIDTH' | translate }}</mat-label>
          <input id="width" matInput formControlName="width">
        </mat-form-field>
      </div>

      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>{{ 'HEIGHT' | translate }}</mat-label>
          <input id="height" matInput formControlName="height">
        </mat-form-field>
      </div>

    </div>

    <div class="row">

      <div class="col-6">
        <mat-form-field class="w-100">
          <mat-label>{{ 'LOCATION.LOCATION' | translate }}</mat-label>
          <input id="locationPretty" matInput formControlName="locationPretty">
        </mat-form-field>
      </div>

      <div class="col-3">
        <mat-form-field  class="w-100">
          <mat-label>{{ 'SUPPLIER' | translate }}</mat-label>
          <mat-select formControlName="supplier">
            @for (supplier of suppliers; track supplier) {
              <mat-option [value]="supplier">{{ supplier.name }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>

    </div>

    <div class="row">

      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>{{ 'MAINCATEGORY' | translate }}</mat-label>
          <input id="mainCategoryDesignation" matInput formControlName="mainCategoryDesignation">
        </mat-form-field>
      </div>

      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>{{ 'SUBCATEGORY' | translate }}</mat-label>
          <input id="subCategoryDesignation" matInput formControlName="subCategoryDesignation">
        </mat-form-field>
      </div>

      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>{{ 'PROCESSING_TYPE' | translate }}</mat-label>
          <input id="toolProcessingType" matInput formControlName="toolProcessingType">
        </mat-form-field>
      </div>

    </div>

    <div class="row" *hasPermission="Permission.TOOL_EDIT">
      <div class="col-3">
        <button type="submit" mat-raised-button color="primary" [disabled]="detailForm.pristine || !detailForm.valid" class="m-1">
          {{ 'SAVE.SAVE' | translate }}
        </button>
        <button type="button" mat-raised-button [disabled]="detailForm.pristine" (click)="onCancel()">
          {{ 'CANCEL' | translate }}
        </button>
      </div>
    </div>

  </form>

</div>
