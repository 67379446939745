<div class="card p-input-filled">
  <p-table
    #dt
    dataKey="dataKey"
    [value]="gridItems"
    [lazy]="true"
    selectionMode="single"
    [(selection)]="selectedItem"
    [resizableColumns]="true" columnResizeMode="expand"
    (onLazyLoad)="fetchDataViaTableComponent($event)"
    [paginator]="true"
    [first]="gridMeta.lazyLoadMeta?.first ?? 0"
    [rows]="gridMeta.lazyLoadMeta?.rows ?? 20"
    [rowsPerPageOptions]="[10, 20, 50]"
    [paginatorDropdownAppendTo]="'body'"
    [totalRecords]="totalRecords"
    [sortMode]="'multiple'"
    [multiSortMeta]="gridMeta.lazyLoadMeta?.multiSortMeta"
    [filters]="gridMeta.filters!"
    [scrollable]="true" scrollHeight="var(--explorer-grid-height)"
    [tableStyle]="{ 'min-width': '50rem' }"
    styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped"
  >

  <ng-template pTemplate="header">
      <tr>
        <th id="inspectionName" style="min-width:10rem" pSortableColumn="inspectionName" pResizableColumn>
          Inspection
          <p-sortIcon field="inspectionName"></p-sortIcon>
          <p-columnFilter type="text" field="inspectionName" display="menu" [showOperator]="false"></p-columnFilter>
        </th>
        <th id="toolInspectionDueDateFormatted" style="min-width:10rem" pSortableColumn="toolInspectionDueDateFormatted" pResizableColumn>
          Inspection due date
          <p-sortIcon field="toolInspectionDueDateFormatted"></p-sortIcon>
          <p-columnFilter type="date" field="toolInspectionDueDateFormatted" display="menu" [showOperator]="false"></p-columnFilter>
        </th>
        <th *showIfDebugMode id="id" style="min-width:8rem" pSortableColumn="id" pResizableColumn>
          Tool id
          <p-sortIcon field="id"></p-sortIcon>
          <p-columnFilter type="numeric" field="id" display="menu" [showOperator]="false"></p-columnFilter>
        </th>
        <th id="toolType" style="min-width:10rem" pSortableColumn="toolType" pResizableColumn>
          Tool Type
          <p-sortIcon field="toolType"></p-sortIcon>
          <p-columnFilter type="text" field="toolType" display="menu" [showOperator]="false"></p-columnFilter>
        </th>
        <th id="name" style="min-width:10rem" pSortableColumn="name" pResizableColumn>
          Name
          <p-sortIcon field="name"></p-sortIcon>
          <p-columnFilter type="text" field="name" display="menu" [showOperator]="false"></p-columnFilter>
        </th>
        <th id="rfid" style="min-width:10rem" pSortableColumn="rfid" pResizableColumn>
          RFID
          <p-sortIcon field="rfid"></p-sortIcon>
          <p-columnFilter type="text" field="rfid" display="menu" [showOperator]="false"></p-columnFilter>
        </th>
        <th id="serialNumber" style="min-width:12rem" pSortableColumn="serialNumber" pResizableColumn>
          Serial Number
          <p-sortIcon field="serialNumber"></p-sortIcon>
          <p-columnFilter type="text" field="serialNumber" display="menu" [showOperator]="false"></p-columnFilter>
        </th>
        <th id="manufacturerName" style="min-width:12rem" pSortableColumn="manufacturerName" pResizableColumn>
          Manufacturer
          <p-sortIcon field="manufacturerName"></p-sortIcon>
          <p-columnFilter type="text" field="locationPretty" display="menu" [showOperator]="false"></p-columnFilter>
        </th>
        <th id="locationPretty" style="min-width:13rem" pSortableColumn="locationPretty" pResizableColumn>
          Location
          <p-sortIcon field="locationPretty"></p-sortIcon>
          <p-columnFilter type="text" field="locationPretty" display="menu" [showOperator]="false"></p-columnFilter>
        </th>
        <th id="supplierPretty" style="min-width:10rem" pSortableColumn="supplierPretty" pResizableColumn>
          Supplier
          <p-sortIcon field="supplierPretty"></p-sortIcon>
          <p-columnFilter type="text" field="supplierPretty" display="menu" [showOperator]="false"></p-columnFilter>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-tool>
      <tr [pSelectableRow]="tool" (dblclick)="onRowDblClick($event, tool.treeNode)" [ngClass]="getRowClass(tool)">
        <td>{{ tool.inspectionName }}</td>
        <td>{{ tool.toolInspectionDueDateFormatted }}</td>
        <td *showIfDebugMode>{{ tool.id }}</td>
        <td>{{ tool.toolType }}</td>
        <td>{{ tool.name }}</td>
        <td>{{ tool.rfid }}</td>
        <td>{{ tool.serialNumber }}</td>
        <td>{{ tool.manufacturerName }}</td>
        <td>{{ tool.locationPretty }}</td>
        <td>{{ tool.supplierPretty }}</td>
      </tr>
    </ng-template>
    <ng-template id="buttonCreate" pTemplate="paginatorleft">
      <button mat-raised-button color="primary" (click)="doInspection()" [disabled]="isInspectionButtonDisabled()"
              *hasPermission="Permission.BOOKING_MANAGER">
        <mat-icon>warning</mat-icon>
        Do inspection
      </button>
    </ng-template>
  </p-table>
</div>
